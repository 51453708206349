import React, { useEffect } from 'react';
import getData, { postData } from './GetData';
import Error from './Error';
import { Button, FormGroup, Label, Input } from 'reactstrap';

export default function A(props) {
    
    return (

<div className="portal_body">
        <div className="portal_container" >
            <div className="portal_left" >
                <div className="launcher_text"> EMR Platform Launcher</div>
                <div className="welcome_title"> </div>
            </div>
            
                <div className="portal_right" >
                    <Label className="sessionprogress_text">
                        Session in progress.
                    </Label>

        
                    <div className="insessionimg" >
                    </div>
                    <Label className="session_text">
                        Please complete and close your session to restart.
                    </Label>
                    
                </div>
            </div>
        </div>
    );
}