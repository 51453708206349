import React from 'react';
import { Spinner, Container, Form, Button, FormGroup, Input, FormFeedback, Label, FormText } from 'reactstrap';
import useSetState from './UseSetState';
import getData, { postData } from './GetData';
import { useNavigate } from 'react-router-dom';
import Error from './Error';

export default function RegisterEndpoint(props) {
    const defaultState = { register: false, posting: false, endpointName: '', clinicCode: '', validate: false, errorMsg: undefined, clinicCodeValidMsg: undefined, endpointNameValidMsg: undefined };
    const [state, setState] = useSetState(defaultState);
    const navigate = useNavigate();
    const endpointId = new URLSearchParams(window.location.search).get("epid");
    const port = new URLSearchParams(window.location.search).get("port");
    const ihps = new URLSearchParams(window.location.search).get("ihps");
    const localHost = ihps ? `https://localhost:${port}` : `http://localhost:${port}`;
    const suggendpoint = new URLSearchParams(window.location.search).get("hname");
    //state.endpointName = new URLSearchParams(window.location.search).get("hname");

    const register = async () => {
        const ip = await getData({}, 'https://wipaddr2.mogatrevsupport.com?format=json', { MogaFL: "MogaWhatsMyIp5920361" }, 1200)
            .catch(function (error) {
                console.log(error.config);
                return {
                    "data": { "ip": "5.5.5.5" }
                };
            });

        try {
            const response = await postData({
                clinicCode: state.clinicCode,
                endpointName: state.endpointName,
                endpointId: endpointId,
                ipAddress: ip.data.ip
            }, '/api/registerEndpoint/registerEndpoint');

            if (response.data.success)
                await postData({
                    clinicCode: state.clinicCode,
                    endpointName: state.endpointName,
                    endpointId: endpointId,
                    ipAddress: ip.data.ip

                }, `${localHost}/api/registerEndpoint/registerEndpoint`);

            //navigate(`/portal?epid=${endpointId}&port=${port}`);
        }
        catch (e) {
            console.log(e);
            setState({ errorMsg: "Failed to Register the endpoint with the server." });
        }
    }

    const validate = async () => {

        let ccvm = undefined;
        let epvm = undefined;
        console.log(state.endpointName);

        if (state.clinicCode.length > 9)
            ccvm = 'Can only be 9 characters long.';
        else if (state.clinicCode.length === 0)
            ccvm = 'Must have a value.';
        else {
            const isValidClinic = await getData({ clinicCode: state.clinicCode }, '/api/registerEndpoint/validateClinicCode');
            console.log(isValidClinic);
            if (!isValidClinic.data.isValid)
                ccvm = 'Not a valid code';
        }

        if (state.endpointName.length === 0) {
            //lets check for hanme
            if (suggendpoint.length > 0) { state.endpointName = suggendpoint }
            else { epvm = 'Must have a value.'; }
            
        }
            
        else if (state.endpointName.length > 255)
            epvm = 'Can only be 255 characters long.';

        const isvalid = !ccvm && !epvm ? true : false;
        
        setState({ endpointNameValidMsg: epvm, clinicCodeValidMsg: ccvm, validate: true, posting: false, register: isvalid });
        if (isvalid)
            register();
    };

    if (state.errorMsg)
        return (<Error message={state.errorMsg} retry={() => setState(defaultState)} />);

    if (!endpointId || !port) 
        return (<Error message='The request is missing imporant information.' />);
    

    if (state.register)
        return (
            <div className="portal_body">
                <div className="portal_container" >
                    <div className="portal_left" >
                        <div className="launcher_text"> EMR Platform Launcher</div>
                        <div className="welcome_title"> </div>
                    </div>

                    <div className="portal_right" >


                        <Label className="waiting_text">
                            Registering Application Endpoint
                        </Label>
                        <div className="waitingauthimg" >
                        </div>
                        <Label className="waiting_text">
                            Please wait this operation only takes a second.
                        </Label>
                    </div>
                </div>
            </div>
        );

    return (
        <div className="portal_body">
                <div className="portal_container" >
                    <div className="portal_left" >
                        <div className="launcher_text"> EMR Platform Launcher</div>
                        <div className="welcome_title"> </div>
                    </div>

                    <div className="portal_right" >

                        <Form>
                            <FormGroup className="register_clinic_code_group">
                                <Label className="register_clinic_label" for="clinicCode">
                                    Clinic Code
                                </Label>
                                <Input
                                    maxLength="9"
                                    disabled={state.posting}
                                    valid={state.validate && !state.clinicCodeValidMsg}
                                    invalid={state.validate && state.clinicCodeValidMsg} value={state.clinicCode} onChange={e => setState({ clinicCode: e.target.value })} />
                                {state.validate && state.clinicCodeValidMsg && <FormFeedback >{state.clinicCodeValidMsg}</FormFeedback>}
                                <FormText>
                                    Enter the code for your clinic.
                                </FormText>
                            </FormGroup>
                            <FormGroup className="register_clinic_endpoint_name_group">
                                <Label className="register_clinic_label" for="clinicCode">
                                    Endpoint Name
                                </Label>
                            <Input
                                    disabled={state.posting}
                                maxLength="255"
                                valid={state.validate && !state.endpointNameValidMsg}
                                placeholder={suggendpoint}
                                invalid={state.validate && state.endpointNameValidMsg} value={state.endpointName} onChange={e => setState({ endpointName: e.target.value })} ></Input>
                                {state.validate && state.endpointNameValidMsg && <FormFeedback >{state.endpointNameValidMsg}</FormFeedback>}
                                <FormText>
                                    Default is computer name 
                                </FormText>
                            </FormGroup>
                        </Form>
                    <Button disabled={state.posting} className="login_button"
                            onClick={(e) => {

                                setState({ posting: true });
                                validate();

                            }}
                            >
                            {state.posting && <Spinner size="sm" />}
                            <span>
                                {state.posting && ' '}Register Endpoint
                            </span>
                        </Button>                    


                    </div>
        
            </div>
        </div>

            );
}