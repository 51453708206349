import React, { useEffect } from 'react';
import getData, { postData } from './GetData';
import Error from './Error';
import { Button, FormGroup, Label, Input } from 'reactstrap';

export default function A(props) {
    
    return (

<div className="portal_body">
        <div className="portal_container" >
            <div className="portal_left" >
                <div className="launcher_text"> EMR Platform Launcher</div>
                <div className="welcome_title"> </div>
            </div>
            
                <div className="portal_right" >
                    

                    <Label className="waiting_text">
                        Authentication screen will open in new browser.
                    </Label>
                    <div className="waitingauthimg" >
                    </div>
                    <Label className="waiting_text">
                        Please Authenticate with your prefered SSO partner.
                    </Label>
                </div>
            </div>
        </div>
    );
}