import React, { useEffect } from 'react';
import getData, { postData } from './GetData';
import useSetState from './UseSetState';
import Keycloak from 'keycloak-js';
import Error from './Error';
import { Button, FormGroup, Label, Input } from 'reactstrap';

export default function A(props) {
    const endpointId = new URLSearchParams(window.location.search).get("epid");
    const port = new URLSearchParams(window.location.search).get("port");
    const sessionid = new URLSearchParams(window.location.search).get("sessionid");
    const ihps = new URLSearchParams(window.location.search).get("ihps");
    const localHost = ihps ? `https://localhost:${port}` : `http://localhost:${port}`;

    const [state, setState] = useSetState({  });
    useEffect(() => {
        async function authentication() {

            const authority = await getData({ endpointId: endpointId }, '/api/clinicAuthority/getClinicAuthority');
            console.log(authority);
            const keycloak = Keycloak({
                url: authority.data.keycloakAuthUrl,
                realm: authority.data.keycloakRealm,
                clientId: authority.data.keycloakResource,
                
            });

            const result = await keycloak.init({ onLoad: 'login-required', promiseType: 'native', scope: 'openid offline_access' });
            return { keycloak, authority: authority.data};
        }
        const getAuthority = async () => {

            if (!endpointId)
                return;

            try {
                console.log("whqat?");
                const authDetails = await authentication();
                console.log("whqat?2");
                if (!authDetails.keycloak.authenticated)
                    setState({ error: true });
                else {
                    
                    console.log(authDetails.authority.clientId);
                    postData({
                        token: authDetails.keycloak.token,
                        endpointId: endpointId,
                        refreshToken: authDetails.keycloak.refreshToken,
                        refreshExpEpoch: authDetails.keycloak.refreshTokenParsed.exp,
                        expEpoch: authDetails.keycloak.tokenParsed.exp,
                        loggedemail: authDetails.keycloak.idTokenParsed.email,
                        url: authDetails.authority.keycloakAuthUrl,
                        realm: authDetails.authority.keycloakRealm,
                        clientId: authDetails.authority.keycloakResource,
                        extendedUI: authDetails.authority.extendedUI
                    }, `${localHost}/api/portal/authenticated`)
                        .then((r) => {
                            setState({ authenticated: true });
                            window.close();
                        }).catch(e => setState({ error: true }));
                     
                }
            }
            catch (e) {
                console.log(e);
                setState({ error: true });
            }
        }
        
        if (!state.error)
            getAuthority();

    }, [state.error]);

    if (state.error)
        return (
            <>
            <div className="error_body">
                <div className="error_container">
                    <h1 className="error_title">Error while authenticating!</h1>

                    <img alt="ohnoes.svg" className="error_img" src='ohnoes.svg' />
                    </div>
                </div>
                </>
        );
    if (!state.authenticated)
        return (
            <>
                <div className="authenticated_body">

                    <div className="authenticated_text"> Single Sign On authenticating</div>

                    <div className="image-container">
                        <div className="authenticatingimg">
                        </div>
                    </div>
                    <div className="authenticated_text">
                        Please wait for the authentication to complete.
                    </div>

                </div>

            </>
        );
    else
        return (

            <>
                <div className="authenticated_body">
                
                        <div className="authenticated_text"> Single Sign On Successful</div>

                <div className="image-container">   
                    <div className="authenticatedimg">
                    </div>
                </div>
                <div className="authenticated_text"> 
                            You may close this window and continue in the application.
                </div>
                
            </div>
                
            </>


        );
}