import axios from 'axios';
export default  function getData(params, url, headers, timeout) {
    let time = timeout;
    if (time === undefined)
        time = 6000;

  
        return axios({
            url: url,
            method: 'get',
            timeout: time,
            params: params,
            headers: headers
        })
   
    ;
}

export function postData(data, url, header) {
   
    let time = 90000;
    
    return axios({
        url: url,
        method: 'post',
        timeout: time,
        data: data,
        headers: {
            ...header,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST'
        }
    });
}