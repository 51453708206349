import React, { useEffect, useRef } from 'react';
import getData, { postData } from './GetData';
import useSetState from './UseSetState';
import Error from './Error';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { groupBy, toODataString } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
    setExpandedState,
    setGroupIds, useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { Link } from "react-router-dom";
import _ from "lodash";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";

export default function Portal(props) {
    const innerWidth = window.innerWidth;
    const endpointId = new URLSearchParams(window.location.search).get("epid");
    const port = new URLSearchParams(window.location.search).get("port");
    const sessionid = new URLSearchParams(window.location.search).get("sessionid");
    const ihps = new URLSearchParams(window.location.search).get("ihps");
    const localHost = ihps ? `https://localhost:${port}` : `http://localhost:${port}`;
    const initialFilter = { logic: "and", filters: [] };
    const [state, setState] = useSetState({
        endpointId, port, sessionid, ihps, localHost,
        launching: false, loading: true, filter: initialFilter
    });
    const stateRef = useRef();
    const setTheme = theme => document.documentElement.classList.add('Theme-'+theme);
    let helpemailtext = "mailto:support@mogatrevsupport.com?subject=EMR Launcher%20Account Request&body=Please create an account for me.%0D%0AMy Name:{FILL IN}%0D%0A MyClinic:{FILL IN}%0D%0A My Location:{FILL IN}%0D%0AAccount Email:";
    
    stateRef.current = state;
    //first import css
    switch (endpointId) {
        case 'LWC':
            setTheme(endpointId.toString());
            break;
        case 'ISD':
            setTheme(endpointId.toString());
            break;
        case 'IRH':
            setTheme(endpointId.toString());
            break;
        case 'MRC':
            setTheme(endpointId.toString());
            break;

        default:
            setTheme('NoTheme');
    }
 

    const getSelections = (query, token) => {
        return postData({ query: `?$count=true&${query}` }, '/api/portal/getProfileSelections', { Authorization: "Bearer " + token });
    }
    const dataStateChange = ({ dataState }) => {
        //setState({ loading: true });

        let newDataState = _.cloneDeep(dataState);

        GridIgnoreCaseFilter(newDataState);
        var queryString = toODataString(newDataState);

        setState({ dataState: { ...dataState, total: state.dataState.total } });


        getSelections(queryString, state.token).then(r => {

            const currentState = stateRef.current;
            currentState.dataState.total = r.data.count;
            currentState.data = r.data.value;
            setState(currentState);
        });
    }

    useEffect(() => {

        const loadData = async () => {
            
            const ip = await getData({}, 'https://wipaddr.mogatrevsupport.com?format=json', { MogaFL: "MogaWhatsMyIp5920361" }, 1200)
                .catch(function (error) {
                    console.log(error);
                    return {
                        "data": { "ip": "5.5.5.5" } }});
            
            const authDetails = await getData({}, `${state.localHost}/api/portal/getAuthDetails`)
                .catch(function (error) {
                    console.log(error.config);
                    setErrorState('Could not connect to local auth.');
                });
            ;

            //check account is real
            const endpoint = await getData({
                endpointId: state.endpointId,
                loggedemail: authDetails.data.loggedemail
            },
                '/api/portal/getPortalDetails',
                { Authorization: "Bearer " + authDetails.data.token })
                .then(function (response) {

                    if (response.status !== 200) {
                        setErrorState('Portal Error with Account');
                        const blindthrow = getData({}, `${state.localHost}/api/portal/badPortal`)
                    }
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.message.includes("401")) {
                        setErrorState('Bad Token');
                        //if its a bad token, newer emr launcher has an endpoint to cause it to try to redirect to new authprod
                        const blindthrow = getData({}, `${state.localHost}/api/portal/badToken`)
                    }
                    else {
                        setErrorState('Error Reading from Portal');
                    }
                    
                    ///if status code 401...
                });
                
            
            const defaultInstance = endpoint.data.instances.filter(i => i.instanceId == endpoint.data.clinic.defaultInstanceId)[0];

            //what to do if the account doesn't exist? this meansa nothing will come back
            if (defaultInstance == null) {
               // helpemailtext = helpemailtext + authDetails.data.loggedemail;
                setState({ email: authDetails.data.loggedemail, noaccount: true, loading: false, loadingDetails: false });
            }
            else {




                if (authDetails.data.extendedUI) {

                    const response = await getSelections('$top=25&$skip=0', authDetails.data.token);

                    setState({
                        data: response.data.value,
                        dataState: { take: 25, skip: 0, total: response.data.count },
                        extended: authDetails.data.extendedUI,
                        ip: ip.data.ip,
                        token: authDetails.data.token,
                        email: authDetails.data.loggedemail,
                        loading: false,
                        loadingDetails: false,
                    });


                }
                else
                    getData({
                        endpointId: state.endpointId,
                        loggedemail: authDetails.data.loggedemail
                    },
                        '/api/portal/getPortalDetails',
                        { Authorization: "Bearer " + authDetails.data.token })
                        .then((r) => {
                            const defaultInstance = r.data.instances.filter(i => i.instanceId == r.data.clinic.defaultInstanceId)[0];

                            //what to do if the account doesn't exist? this meansa nothing will come back
                            if (defaultInstance == null) {
                                setState({ email: authDetails.data.loggedemail, noaccount: true, loading: false, loadingDetails: false });
                            }
                            else {
                                const profiles = r.data.profiles.filter(p => p.instanceId == defaultInstance.instanceId);
                                const defaultProfile = profiles.filter(p => p.instanceId == defaultInstance.instanceId)[0];
                                setState({
                                    extended: authDetails.data.extendedUI,
                                    ip: ip.data.ip,
                                    token: authDetails.data.token,
                                    profiles: profiles,
                                    mode: 'Normal',
                                    selectedInstance: defaultInstance,
                                    email: authDetails.data.loggedemail,
                                    selectedProfile: defaultProfile,
                                    data: r.data, loading: false, loadingDetails: false
                                });
                            }

                        }).catch(e => {
                            console.log(e);
                            setErrorState('Could not connect to the server.');
                        });
            }
        };

        loadData();

    }, [state.error]);


    if (state.error)
        return (<Error message={state.error} retry={e => setState({ launching: false, loading: true, error: undefined })} />);

    if (!state.endpointId || !state.port)
        return (<Error message='The request is missing important information.' />);

    if (state.launching)
        return (
            <div className="portal_body">
                <div className="portal_container" >
                    <div className="portal_left" >
                        <div className="launcher_text"> EMR Platform Launcher</div>
                        <div className="welcome_title"> </div>
                    </div>

                    <div className="portal_right" >
                        <Label className="authenticated_text">
                            Securing and Configuring your session
                        </Label>


                        <div className="configuringimg" >
                        </div>
                        <Label className="authenticated_text">
                            Please accept any requests to trust mmhosted.net.
                        </Label>

                    </div>
                </div>
            </div>

        );
    if (state.loading || state.loadingDetails)
        return (
            <div className="portal_body">
                <div className="portal_container" >
                    <div className="portal_left" >
                        <div className="launcher_text"> EMR Platform Launcher</div>
                        <div className="welcome_title"></div>
                    </div>

                    <div className="portal_right" >
                        <Label className="authenticated_text">
                            Loading Details
                        </Label>


                        <div className="configuringimg" >
                        </div>
                        <Label className="authenticated_text">
                            Loading Details
                        </Label>

                    </div>
                </div>
            </div>

        );
    if (state.noaccount)
        return (
            <div className="portal_body">
                <div className="portal_container" >
                    <div className="portal_left" >
                        <div className="launcher_text"> EMR Platform Launcher</div>


                    </div>

                    <div className="portal_right" >
                        <Label className="authenticated_text"> No Account Found

                        </Label>
                        <Label className="authenticated_text">
                            {state.email}
                        </Label>
                        <Label className="authenticated_text">
                            Please contact your manager for account creation.
                        </Label>
                        <Label className="authenticated_text">
                            <a href={helpemailtext + state.email}>Click here to email your request directly.</a>
    
                        </Label>
                        
                        <div className="noaccountimg" >
                        </div>


                    </div>
                </div>
            </div>

        );
    if (state.loginexpired)
        return (
            <div className="portal_body">
                <div className="portal_container" >
                    <div className="portal_left" >
                        <div className="launcher_text"> EMR Platform Launcher</div>
                        <div className="welcome_title">{state.email} </div>

                    </div>

                    <div className="portal_right" >
                        <Label className="authenticated_text"> Authentication and Token has expired.
                        </Label>

                        <div className="noaccountimg" >
                        </div>
                        <Label className="authenticated_text">
                            Please close the app and restart to reauthenticate.
                        </Label>

                    </div>
                </div>
            </div>

        );

    const onEnviornmentChange = (e) => {

        const selectedInstance = state.data.instances.filter(i => i.instanceId == e.target.selectedOptions[0].id)[0];

        const profiles = state.data.profiles.filter(p => p.instanceId == selectedInstance.instanceId);

        setState({
            profiles: profiles,
            selectedInstance: selectedInstance,
            selectedProfile: profiles[0],
        });
    };

    // const background = state.data.clinic.clinicBackground ? `url("${state.data.clinic.clinicBackground}")` : 'url("/defaultPortal.png")';
    const background = state.data && state.data.clinic && state.data.clinic.clinicBackground ? `url("${state.data.clinic.clinicBackground}")` : '';

    console.log(state);


    if (state.extended)

        return (

            <div className="extended_container">
                <Label className="authenticated_text">
                    You are logged in as: {state.email}
                </Label>
                <Grid
                    className="extended_grid"
                    data={state.data}
                    sortable={true}
                    scrollable={true}
                    pageable={true}
                    pageSize={25}
                    buttonCount={5}
                    rowHeight={5}
                    resizable={true}
                    groupable={true}
                    filterable={true}

                    {...state.dataState}
                    onDataStateChange={dataStateChange}
                    previousNext
                >
                    <GridColumn field="clinicName" title="Clinic Name" columnMenu={GridTextColumnMenu}  />
                    <GridColumn field="profileName" title="Profile Name" columnMenu={GridTextColumnMenu} />
                    <GridColumn field="instanceName" title="Instance Name" columnMenu={GridTextColumnMenu} />
                    <GridColumn field="description" title="Description" columnMenu={GridTextColumnMenu} />
                    <GridColumn width="25" cell={VpnRequired} field="vpnRequired" title="VPN Required" filter="boolean" columnMenu={GridTextColumnMenu} />
                    <GridColumn width="25" cell={FullRdp} field="fullRDP" title="Full RDP" filter="boolean" columnMenu={GridTextColumnMenu} />
                    <GridColumn cell={(cellProps) => <ProfileName {...cellProps} onActionClick={(item) => {

                        onLogin(item.instanceId, item.profileId, "Normal");
                    }} />} filterable={false} field="profileEnabled" title="" />
                </Grid>
            </div>
        );
    else
        return (<div className="portal_body"
        >
            <div className="portal_container"
                style={{

                }}
            >
                <div className="portal_left" >
                    <div className="launcher_text"> EMR Platform Launcher</div>
                    <div className="welcome_title">{state.data.email} {endpointId.toString()}</div>
                    
                </div>

                <div className="portal_right"
                    style={{
                        backgroundImage: background
                    }}
                >
                    <div className="env_form_group">
                        <FormGroup>
                            <Label className="env_label" for="Environment">
                                Environments {document.documentElement.classList.contains("ISD").toString()}
                            </Label>

                            <Input
                                className="dropdown-dark"
                                id="Environment"
                                name="Environment"
                                type="select"
                                value={state.selectedInstance.instanceName}
                                onChange={onEnviornmentChange}>
                                {state.data.instances.map(i => <option id={i.instanceId} key={i.instanceId}>{i.instanceName} </option>)}
                            </Input>

                        </FormGroup>
                    </div>
                    <div className="mode_group">
                        <FormGroup>
                            <Label className="mode_label" for="Profiles">
                                Login Mode
                            </Label>
                            <Input
                                className="dropdown-dark"
                                id="mode"
                                name="mode"
                                type="select"
                                value={state.selectedMode}
                                onChange={(e) => setState({ mode: e.target.value })}>
                                {state.data.modes.map((i, idx) => <option key={idx}>{i} </option>)}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="profile_form_group">
                        <FormGroup>
                            <Label className="profile_label" for="Profiles">
                                Profiles
                            </Label>
                            <Input
                                className="dropdown-dark"
                                id="Profiles"
                                name="Profiles"
                                type="select"
                                onChange={e => {
                                    setState({ selectedProfile: state.profiles.filter(f => f.profileName == e.target.value)[0] });
                                }}
                                value={state.selectedProfile.profileName}>
                                {state.profiles.map(i => <option key={i.profileId}>{i.profileName} </option>)}
                            </Input>
                        </FormGroup>
                    </div>
                    <Button className="login_button" onClick={e => onLogin(state.selectedInstance.instanceId, state.selectedProfile.profileId, state.mode)}>Login</Button>


                   

                </div>
            </div>

        </div >);

    function setErrorState(m) {
        setState({ error: m ? m : '', loading: false, loadingDetails: false, keycloak: { authorized: false } });
    }
    function onLogin(iid, pid, mode) {
        console.log(iid, pid, mode);

        setState({ launching: true });
        const badresult = () => setState({ loginexpired: true, noaccount: false, loading: false, loadingDetails: false });

        getData({}, `${state.localHost}/api/portal/getAuthDetails`)
            .then(
                (authDetails) => {
                    
                    if (authDetails.data && authDetails.data.success && authDetails.data.token) {
                        
                        postData({
                            profileId: pid,
                            token: authDetails.data.token,
                            ip: state.ip,
                            mode: mode,
                            environmentId: iid
                        }, `${state.localHost}/api/portal/launchIdeas`)
                            .then((r) => {
                                console.log(r);
                                if (!r.data.success) {
                                    console.log(r);
                                    badresult();
                                }
                                else {
                                    setState({ launching: false });
                                }
                            })
                        .catch (() => badresult());

                    }
                    else
                        badresult();
                }).catch(() => badresult());
    }
}
function FullRdp(props) {
    const { dataItem, colSpan, isSelected, id, ariaColumnIndex, onActionClick } = props;
    const navigationAttributes = useTableKeyboardNavigation(id);

    const profileName = dataItem["profileName"];

    return (<td
        className='profle_grid_column'
        colSpan={colSpan}
        role={"gridcell"}
        aria-label="profile name"
        aria-colindex={ariaColumnIndex}
        aria-selected={isSelected}
        {...navigationAttributes}>

        {showCheck(dataItem["fullRDP"])}
    </td>);
}
function VpnRequired(props) {
    const { dataItem, colSpan, isSelected, id, ariaColumnIndex, onActionClick } = props;
    const navigationAttributes = useTableKeyboardNavigation(id);

    const profileName = dataItem["profileName"];

    return (<td
        className='profle_grid_column'
        colSpan={colSpan}
        role={"gridcell"}
        aria-label="profile name"
        aria-colindex={ariaColumnIndex}
        aria-selected={isSelected}
        {...navigationAttributes}>

        {showCheck(dataItem["vpnRequired"])}
    </td>);
};
function showCheck(show) {
    if (show)
        return <span class="k-icon k-i-check"></span>
    else
        return <></>
    //return <span class="k-icon k-i-close"></span>
}
function ProfileName(props) {
    const { dataItem, colSpan, isSelected, id, ariaColumnIndex, onActionClick } = props;
    const navigationAttributes = useTableKeyboardNavigation(id);

    const profileName = dataItem["profileName"];

    return (<td
        className='profle_grid_column'
        colSpan={colSpan}
        role={"gridcell"}
        aria-label="profile name"
        aria-colindex={ariaColumnIndex}
        aria-selected={isSelected}
        {...navigationAttributes}>

        {!!dataItem.profileEnabled && <span><Link to="" className="profile_link" onClick={e => onActionClick(dataItem)}>Launch RDP</Link></span>}

    </td>);
}
function GridIgnoreCaseFilter(dataState) {
    return ApplyFilter(dataState, (filter) => {
        if (filter.value &&
            filter.field !== "vpnRequired" && filter.field !== "fullRDP") {
            filter.value = filter.value.toLowerCase();
            filter.ignoreCase = true;
        }
    });
}
function ApplyFilter(dataState, operation) {
    const apply = (filter, filters) => {
        operation(filter, filters); if (filter.filters && Array.isArray(filter.filters))
            for (var i = 0; i < filter.filters.length; i++)
                apply(filter.filters[i], filter.filters);
    }; if (dataState.filter && dataState.filter.filters)
        for (var i = 0; i < dataState.filter.filters.length; i++)
            apply(dataState.filter.filters[i], dataState.filter.filters); return dataState;
}
function GridTextColumnMenu(menuProps) {
    return (<div><GridColumnMenuFilter hideSecondFilter expanded {...menuProps} /></div>);
}
