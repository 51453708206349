import { Button } from 'reactstrap';
export default function Error(props) {
    const { message, retry } = props;
    return  (<div className="error_body">
        <div className="error_container">
                <h1 className="error_title">Error</h1>
                <h5 className="error_message">{message}</h5>
                {retry && <Button className="error_retry_button" onClick={retry}>Try Again</Button>}
                <img alt="ohnoes.svg" className="error_img" src='ohnoes.svg' />
            </div>
        </div>);
}