import RegisterEndpoint from "./components/RegisterEndPoint";
import Portal from "./components/Portal";
import Authentication from "./components/Authentication";
import WaitingAuth from "./components/WaitingAuth";
import InSession from "./components/InSession"
const AppRoutes = [
  {
    path: '/registerEndpoint',
    element: <RegisterEndpoint />
    },
    {
        path: '/authentication',
        element: <Authentication />
    },
    {
        path: '/portal',
        element: <Portal />
    },
    {
        path: '/waitingauth',
        element: <WaitingAuth />
    },
    {
        path: '/insession',
        element: <InSession />
    }
];

export default AppRoutes;
