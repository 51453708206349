import { useState } from "react";

export default function useSetState(initialState) {
	const [state, writeState] = useState(initialState);

	return [
		state,
		function (obj) {
			const newState = { ...state };

			for (var p in obj)
				newState[p] = obj[p];
			writeState(newState);
		}
	];
}